import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "./structure/container"

export default function Banner(props) {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section
      className="relative flex justify-center items-center"
      style={{ minHeight: `500px` }}
    >
      <Img
        fluid={query.file.childImageSharp.fluid}
        alt="Banner Image"
        className="object-cover w-full"
        style={{ height: `100vh`, minHeight: `500px` }}
      />
      <div
        className="absolute inset-0"
        style={{ backgroundColor: `rgba(10, 10, 10, .5)` }}
      ></div>
      <div className="absolute text-white text-center">
        <Container className="flex flex-col items-center ">
          <h1
            className="text-6xl leading-none pb-4 mb-5"
            style={{
              borderBottom: `dashed white 2px`,
            }}
          >
            {/* Handmade Face Coverings */}
            Thank You!
          </h1>
          <p className="text-xl mb-5">
            {/* A neighborly gesture to help you protect others */}
            We'd like to extend our thanks to those who have decided to purchase
            our masks. Unfortunately, we are no longer taking orders and will be
            taking down the site. We have enjoyed the excitement and kind
            complements around our masks and loved hearing about how they gave a
            splash of color in your day when we all needed it the most.
            <br />
            <br />
            If you would like to continue to follow our work, a new website will
            be coming soon featuring Quilts, Black Walnut products and more.
          </p>
        </Container>
      </div>
    </section>
  )
}
