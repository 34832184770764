// npm
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// structure
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/structure/container"
import Section from "../components/structure/section"
import Heading from "../components/structure/heading"

// contentful
import Banner from "../components/banner"
import Tidbits from "../components/tidbits"

export default function Home() {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "sample.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 800, quality: 70) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout slug="index">
      <SEO
        title=""
        description={`At only five dollars, our masks are priced to be easy on your pocket so you can protect
            yourself and the entire family. We like to keep things fresh with a variety of prints, with the wish
            to have a mask for every you. From sports to construction, puppies to super heros, seasons and occupations.
            Our face coverings are sewn using a "tie style" strap, so you can achieve a comfortable fit.
            The CDC has reported that two layers of 100% cotton can filter small particles better than a surgical mask.
            Child sizes available. You can machine wash with regular laundry or wash by hand.`}
      />
      <Banner />
      {/* <Section>
        <Container className="flex flex-col items-center">
          <img src="/images/icons8-price-tag-64.png" alt="" />
          <Heading level="2" className="lg:text-5xl text-center">
            <span style={{ textDecoration: "line-through" }}>Only $5</span>
            <br />
            Now Only $3!
          </Heading>
          <p
            className="text-md lg:text-xl text-center mb-6"
            style={{ maxWidth: `800px` }}
          >
            Our masks are priced to be easy on your pocket so you can protect
            yourself and the entire family.
          </p>
          <Link
            to="/prints/"
            className="text-xl border border-solid border-black border-2 px-4 py-2 rounded-lg"
          >
            Look at our Prints
          </Link>
        </Container>
      </Section>
      <Section>
        <Container className="flex flex-col items-center">
          <Heading level="2" className="lg:text-5xl text-center">
            Protection with Expression
          </Heading>
          <p
            className="text-md lg:text-xl text-center mb-6"
            style={{ maxWidth: `800px` }}
          >
            We like to keep things fresh with a variety of prints, with the wish
            to have a mask for every you. From sports to construction, puppies
            to super heros, seasons and occupations.
          </p>
        </Container>
      </Section>
      <Section>
        <Container>
          <Img
            fluid={data.file.childImageSharp.fluid}
            className="mx-auto w-full md:w-7/12 rounded-lg border border-black"
          />
        </Container>
      </Section>
      <Tidbits
        bits={[
          {
            icon: "/images/icons8-rope-50.png",
            title: "Tie to Size",
            content:
              'Our face coverings are sewn using a "tie style" strap, so you can achieve a comfortable fit.',
          },
          {
            icon: "/images/icons8-fabric-sample-64.png",
            title: "100% Cotton",
            content:
              "We use 100% cotton throughout our face coverings. The CDC has reported that two layers of this fabric can filter small particles better than a surgical mask.",
          },
          {
            icon: "/images/icons8-children-64.png",
            title: "Child Size",
            content:
              "Offering a variety of playful prints to help encourage your child to want to wear one.",
          },
          {
            icon: "/images/icons8-washing-machine-64.png",
            title: "Washable",
            content: () => {
              return (
                <span>
                  You can machine wash with regular laundry or wash by hand. For
                  more info on mask care check out our{" "}
                  <Link to="/info/" className="underline hover:text-blue-600">
                    info page
                  </Link>
                  .
                </span>
              )
            },
          },
        ]}
      />
      <Section>
        <Container className="flex flex-col items-center">
          <h2 className="text-3xl lg:text-5xl text-center pb-5">
            Recommendations
          </h2>
          <p
            className="text-md lg:text-xl text-center pb-5"
            style={{ maxWidth: `800px` }}
          >
            The CDC recommends that people wear cloth face coverings in public
            settings and when around people who don't live in your household,
            especially when other social distancing measure are difficult to
            maintain. Cloth coverings may help prevent people who have COVID-19
            from spreading the virus to others.
          </p>
        </Container>
      </Section>
      <Tidbits
        title={() => {
          return (
            <span>
              Specifications
              <img
                src="/images/icons8-sewing-tape-measure-50.png"
                alt=""
                className="inline-block ml-4"
                style={{ width: `40px` }}
              />
            </span>
          )
        }}
        bits={[
          {
            title: "Adult",
            content: () => {
              return (
                <ul>
                  <li>Mask Area: approx. 4 x 8 inches</li>
                  <li>Overall Strap Length: 40 ~ 45 inches</li>
                </ul>
              )
            },
          },
          {
            title: "Children",
            content: () => {
              return (
                <ul>
                  <li>Mask Area: approx. 3 x 6 inches</li>
                  <li>Overall Strap Length: 35 ~ 40 inches</li>
                </ul>
              )
            },
          },
        ]}
      /> */}
    </Layout>
  )
}
